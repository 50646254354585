import React, { useState } from 'react'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonError } from '../Button'
import { BalanceInfo } from '../../state/fly/hooks'
import { useFlyContract } from '../../hooks/useContract'
import { SubmittedView, LoadingView } from '../ModalViews'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useActiveWeb3React } from '../../hooks'
import { calculateGasMargin } from 'utils'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  balanceInfo: BalanceInfo
}

export default function UnlockFLyModal({ isOpen, onDismiss, balanceInfo }: StakingModalProps) {
  const { account } = useActiveWeb3React()

  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)

  function wrappedOnDismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  const flyContract = useFlyContract()

  async function onUnlock() {
    if (flyContract && balanceInfo?.unlockableBalance) {
      setAttempting(true)
      await flyContract.estimateGas['unlock'](account, {}).then(estimatedGasLimit => {
        return flyContract
          .unlock(account, { value: null, gasLimit: calculateGasMargin(estimatedGasLimit) })
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `Unlocking ${balanceInfo?.unlockableBalance?.toSignificant(4)} FLy`
            })
            return response.hash
          })
          .catch((error: any) => {
            setAttempting(false)
            console.log(error)
          })
      })
    }
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!balanceInfo?.unlockableBalance) {
    error = error ?? 'Enter an amount'
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Unlock</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          {balanceInfo?.unlockableBalance && (
            <AutoColumn justify="center" gap="md">
              <TYPE.body fontWeight={600} fontSize={36}>
                {balanceInfo?.unlockableBalance?.toFixed(4, { groupSeparator: ',' }) ?? '-'}
              </TYPE.body>
              <TYPE.body>Unlockable FLy</TYPE.body>
            </AutoColumn>
          )}
          {/* <TYPE.subHeader style={{ textAlign: 'center' }}>
            When you unlock tokens your acbal remains in the mining pool.
          </TYPE.subHeader> */}
          <ButtonError disabled={!!error} error={!!error && !!balanceInfo?.unlockableBalance} onClick={onUnlock}>
            {error ?? 'Unlock'}
          </ButtonError>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.body fontSize={20}>Unlocking {balanceInfo?.unlockableBalance?.toSignificant(6)} FLy</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Unlocking FLy!</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
