import React, { useContext, useCallback, useState } from 'react'
//import DatePicker from 'react-datepicker'
import styled, { DefaultTheme, ThemeContext } from 'styled-components'
import { TYPE } from 'theme'
import { AutoColumn } from '../../components/Column'
import { RowBetween } from '../../components/Row'
// import { isMobile } from 'react-device-detect'
import moment from 'moment'
import { DatetimePicker } from 'rc-datetime-picker'
import 'font-awesome/css/font-awesome.min.css'
import 'rc-datetime-picker/dist/picker.css'
import './picker-ext.css'

const InputPanel = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border-radius: 1.25rem;
  background-color: ${({ theme }) => theme.bg1};
  z-index: 1;
  width: 100%;
`

const ContainerRow = styled.div<{ error: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  border: 1px solid ${({ error, theme }) => (error ? theme.red1 : theme.bg2)};
  transition: border-color 300ms ${({ error }) => (error ? 'step-end' : 'step-start')},
    color 500ms ${({ error }) => (error ? 'step-end' : 'step-start')};
  background-color: ${({ theme }) => theme.bg1};
`

const InputContainer = styled.div`
  flex: 1;
  padding: 1rem;
`
export default function DateTimeInputPanel({
  id,
  value,
  onChange
}: {
  id?: string
  // the typed string value
  value: moment.Moment | undefined
  // triggers whenever the typed value changes
  onChange: (value: moment.Moment, seconds: number) => void
}) {
  const theme = useContext(ThemeContext as React.Context<DefaultTheme>)
  const [error, setError] = useState(false)

  const handleInput = useCallback(
    (date: moment.Moment) => {
      const seconds = date.diff(moment(), 'seconds')
      setError(seconds < 300)
      onChange(date, seconds)
    },
    [onChange, setError]
  )
  return (
    <InputPanel id={id}>
      <ContainerRow error={error}>
        <InputContainer>
          <AutoColumn gap="md">
            <RowBetween>
              <TYPE.black color={theme.text2} fontWeight={500} fontSize={14}>
                Date &amp; Time to lock
              </TYPE.black>
            </RowBetween>
            <DatetimePicker moment={value} onChange={handleInput} />
            {/* {!isMobile ? (
              <DatePicker
                disabled={!!error}
                selected={value}
                onChange={handleInput}
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy HH:mm"
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={5}
                inline
              />
            ) : null} */}
          </AutoColumn>
        </InputContainer>
      </ContainerRow>
    </InputPanel>
  )
}
