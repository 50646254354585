import React, { useState, useCallback } from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
//import { Link } from 'react-router-dom'

import { JSBI, TokenAmount } from '@uniswap/sdk'
import { useCurrency } from '../../hooks/Tokens'
import { useWalletModalToggle } from '../../state/application/hooks'
import { TYPE } from '../../theme'

import { RowBetween } from '../../components/Row'
import { CardSection, DataCard, CardNoise, CardBGImage } from '../../components/fly/styled'
import { ButtonPink, ButtonPrimary } from '../../components/Button'
import { useBalanceInfo } from '../../state/fly/hooks'

import UnlockFLyModal from '../../components/fly/UnlockFLyModal'

import { useActiveWeb3React } from '../../hooks'
// import { useColor } from '../../hooks/useColor'
import { CountUp } from 'use-count-up'

import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { usePair } from '../../data/Reserves'
import usePrevious from '../../hooks/usePrevious'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { BIG_INT_ZERO } from '../../constants'
import CurrencyLogo from 'components/CurrencyLogo'
import TransferModal from 'components/fly/TransferModal'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const PositionInfo = styled(AutoColumn)<{ dim: any }>`
  position: relative;
  max-width: 640px;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const BottomSection = styled(AutoColumn)`
  border-radius: 12px;
  width: 100%;
  position: relative;
`

const StyledDataCard = styled(DataCard)<{ bgColor?: any; showBackground?: any }>`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #1e1a31 0%, #3d51a5 100%);
  z-index: 2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background: ${({ theme, bgColor, showBackground }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${bgColor} 0%,  ${showBackground ? theme.black : theme.bg5} 100%) `};
`

const StyledBottomCard = styled(DataCard)<{ dim: any }>`
  background: ${({ theme }) => theme.bg3};
  opacity: ${({ dim }) => (dim ? 0.4 : 1)};
  margin-top: -40px;
  padding: 0 1.25rem 1rem 1.25rem;
  padding-top: 32px;
  z-index: 1;
`

const FLyData = styled(DataCard)`
  background: none;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 1rem;
  z-index: 1;
`

// const VoteCard = styled(DataCard)`
//   background: radial-gradient(76.02% 75.41% at 1.84% 0%, #27ae60 0%, #000000 100%);
//   overflow: hidden;
// `

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 12px;
  `};
`

// const StyledExternalLink = styled(ExternalLink)`
//   color: ${({ theme }) => theme.text1};
// `

export default function MyFLy() {
  const { account, chainId } = useActiveWeb3React()

  const balanceInfo = useBalanceInfo()
  // get currencies and pair
  const [currencyA, currencyB] = [useCurrency(balanceInfo?.totalBalance?.token?.symbol), useCurrency('ETH')]
  const tokenA = wrappedCurrency(currencyA ?? undefined, chainId)
  const WETH = wrappedCurrency(currencyB ?? undefined, chainId)
  const [, stakingTokenPair] = usePair(tokenA, WETH)

  //const userLiquidityUnstaked = useTokenBalance(account ?? undefined, stakingInfo?.stakedAmount?.token)
  //const showAddLiquidityButton = Boolean(stakingInfo?.stakedAmount?.equalTo('0') && userLiquidityUnstaked?.equalTo('0'))

  // toggle for transfer modal

  const [showTransferModal, setShowTransferModal] = useState(false)
  const [showUnlockModal, setShowUnlockModal] = useState(false)

  // fade cards if nothing staked or nothing earned yet
  const disableTop = !balanceInfo?.totalBalance || balanceInfo.totalBalance.equalTo(JSBI.BigInt(0))
  const token = balanceInfo?.totalBalance?.token
  //const backgroundColor = useColor(token)

  // get WETH value of FLy tokens
  let valueOfTotalAmountInWETH: TokenAmount | undefined
  let valueOfBalanceAmountInWETH: TokenAmount | undefined
  if (stakingTokenPair && balanceInfo && balanceInfo.totalBalance && token && WETH) {
    // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
    valueOfTotalAmountInWETH = new TokenAmount(
      WETH,
      stakingTokenPair.priceOf(token).quote(balanceInfo.totalBalance).raw
    )
  }

  if (stakingTokenPair && balanceInfo && balanceInfo.balance && token && WETH) {
    // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
    valueOfBalanceAmountInWETH = new TokenAmount(WETH, stakingTokenPair.priceOf(token).quote(balanceInfo.balance).raw)
  }

  const countUpAmount = balanceInfo?.unlockableBalance?.toFixed(4) ?? '0'
  const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'

  // get the USD value of staked WETH
  const USDPrice = useUSDCPrice(WETH)
  const valueOfTotalAmountInUSDC = valueOfTotalAmountInWETH && USDPrice?.quote(valueOfTotalAmountInWETH)
  const valueOfBalanceAmountInUSDC = valueOfBalanceAmountInWETH && USDPrice?.quote(valueOfBalanceAmountInWETH)

  const toggleWalletModal = useWalletModalToggle()

  const handleConnectClick = useCallback(() => {
    if (!account) {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  return (
    <PageWrapper gap="lg" justify="center">
      <RowBetween style={{ gap: '24px' }}>
        <TYPE.mediumHeader style={{ margin: 0 }}>Your FLy tokens breakdown</TYPE.mediumHeader>
        <CurrencyLogo currency={currencyA ?? undefined} />
      </RowBetween>

      <DataRow style={{ gap: '24px' }}>
        <FLyData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>Total, FLy</TYPE.body>
            <TYPE.body fontSize={24} fontWeight={500}>
              {balanceInfo?.totalBalance?.toFixed(4, { groupSeparator: ',' }) ?? '-'}
            </TYPE.body>
          </AutoColumn>
        </FLyData>
        <FLyData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>Total, {valueOfTotalAmountInUSDC ? '$' : 'ETH'}</TYPE.body>
            <TYPE.body fontSize={24} fontWeight={500}>
              {valueOfTotalAmountInUSDC
                ? `$${valueOfTotalAmountInUSDC.toFixed(0, { groupSeparator: ',' })}`
                : `${valueOfTotalAmountInWETH?.toFixed(4, { groupSeparator: ',' }) ?? '-'} ETH`}
            </TYPE.body>
          </AutoColumn>
        </FLyData>
      </DataRow>
      <DataRow style={{ gap: '24px' }}>
        <FLyData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>Balance, FLy</TYPE.body>
            <TYPE.body fontSize={24} fontWeight={500}>
              {balanceInfo?.balance?.toFixed(4, { groupSeparator: ',' }) ?? '-'}
            </TYPE.body>
          </AutoColumn>
        </FLyData>
        <FLyData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>Balance, {valueOfBalanceAmountInUSDC ? '$' : 'ETH'}</TYPE.body>
            <TYPE.body fontSize={24} fontWeight={500}>
              {valueOfBalanceAmountInUSDC
                ? `$${valueOfBalanceAmountInUSDC.toFixed(0, { groupSeparator: ',' })}`
                : `${valueOfBalanceAmountInWETH?.toFixed(4, { groupSeparator: ',' }) ?? '-'} ETH`}
            </TYPE.body>
          </AutoColumn>
        </FLyData>
      </DataRow>

      {balanceInfo && (
        <>
          <TransferModal
            isOpen={showTransferModal}
            onDismiss={() => setShowTransferModal(false)}
            balanceInfo={balanceInfo}
          />
          <UnlockFLyModal
            isOpen={showUnlockModal}
            onDismiss={() => setShowUnlockModal(false)}
            balanceInfo={balanceInfo}
          />
        </>
      )}

      <PositionInfo gap="lg" justify="center" dim={false}>
        <BottomSection gap="lg" justify="center">
          <StyledDataCard disabled={disableTop} showBackground={true}>
            <CardSection>
              <CardBGImage desaturate />
              <CardNoise />
              <AutoColumn gap="md">
                <RowBetween>
                  <TYPE.white fontWeight={600}>Locked FLy</TYPE.white>
                </RowBetween>
                <RowBetween style={{ alignItems: 'baseline' }}>
                  <TYPE.white fontSize={36} fontWeight={600}>
                    {balanceInfo?.lockedBalance?.toFixed(4, { groupSeparator: ',' }) ?? '-'}
                  </TYPE.white>
                  <TYPE.white>{currencyA?.symbol}</TYPE.white>
                </RowBetween>
              </AutoColumn>
            </CardSection>
          </StyledDataCard>
          <StyledBottomCard dim={balanceInfo?.unlockableBalance?.equalTo(JSBI.BigInt(0))}>
            <CardBGImage desaturate />
            <CardNoise />
            <AutoColumn gap="sm">
              <RowBetween>
                <div>
                  <TYPE.black>Your unlockable FLy</TYPE.black>
                </div>
                {balanceInfo?.unlockableBalance && JSBI.notEqual(BIG_INT_ZERO, balanceInfo?.unlockableBalance?.raw) && (
                  <ButtonPink
                    padding="8px"
                    borderRadius="8px"
                    width="fit-content"
                    onClick={() => setShowUnlockModal(true)}
                  >
                    Unlock
                  </ButtonPink>
                )}
              </RowBetween>
              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.largeHeader fontSize={36} fontWeight={600}>
                  <CountUp
                    key={countUpAmount}
                    isCounting
                    decimalPlaces={4}
                    start={parseFloat(countUpAmountPrevious)}
                    end={parseFloat(countUpAmount)}
                    thousandsSeparator={','}
                    duration={1}
                  />
                </TYPE.largeHeader>
              </RowBetween>
            </AutoColumn>
          </StyledBottomCard>
        </BottomSection>
        <TYPE.main style={{ textAlign: 'center' }} fontSize={14}>
          <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
            ⭐️
          </span>
          When you unlock FLy tokens, they will be available for regular transfer!
        </TYPE.main>

        <DataRow style={{ marginBottom: '1rem' }}>
          {!account && (
            <ButtonPrimary padding="8px" borderRadius="8px" width="160px" onClick={() => handleConnectClick()}>
              Connect Wallet
            </ButtonPrimary>
          )}
          {balanceInfo?.balance?.greaterThan(JSBI.BigInt(0)) && (
            <>
              <ButtonPrimary padding="8px" borderRadius="8px" width="160px" onClick={() => setShowTransferModal(true)}>
                Transfer
              </ButtonPrimary>
            </>
          )}
        </DataRow>
      </PositionInfo>
    </PageWrapper>
  )
}
