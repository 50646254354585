import React, { useState, useCallback, useContext, useEffect } from 'react'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled, { DefaultTheme, ThemeContext } from 'styled-components'
import { RowBetween, RowFixed } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonError } from '../Button'
import Toggle from '../Toggle'
import QuestionHelper from '../QuestionHelper'
import CurrencyInputPanel from '../CurrencyInputPanel'
// import { TokenAmount, Pair } from '@uniswap/sdk'
// import { useActiveWeb3React } from '../../hooks'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { useFlyContract } from '../../hooks/useContract'
import { BalanceInfo, useTransferInfo } from '../../state/fly/hooks'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'

import { LoadingView, SubmittedView } from '../ModalViews'
import 'react-datepicker/dist/react-datepicker.css'
import AddressInputPanel from 'components/AddressInputPanel'
import ReasonInputPanel from 'pages/MyFLy/ReasonInputPanel'
import useENS from 'hooks/useENS'
import DateTimeInputPanel from 'pages/MyFLy/DateTimeInputPanel'
import { calculateGasMargin } from 'utils'
import { formatBytes32String } from '@ethersproject/strings'
import moment from 'moment'

const MIN_SECONDS_TO_LOCK = 300

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface TransferModalProps {
  isOpen: boolean
  onDismiss: () => void
  balanceInfo: BalanceInfo
}

export default function TransferModal({ isOpen, onDismiss, balanceInfo }: TransferModalProps) {
  // track and parse user input

  const theme = useContext(ThemeContext as React.Context<DefaultTheme>)
  const [typedValue, setTypedValue] = useState('')
  const { parsedAmount, error } = useTransferInfo(typedValue, balanceInfo)

  const [lockDate, setLockDate] = useState<moment.Moment>(moment().add(10, 'minutes'))
  const [lockSeconds, setLockSeconds] = useState(lockDate.diff(moment(), 'seconds'))

  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()

  const [attempting, setAttempting] = useState<boolean>(false)
  const [transfwerWithLock, setTransfwerWithLock] = useState<boolean>(false)

  const [recipient, setRecipient] = useState('')
  function handleRecipientType(val: string) {
    setRecipient(val)
  }

  const [reason, setReason] = useState('')
  useEffect(() => {
    if (isOpen) {
      const dt = moment().add(10, 'minutes')
      setLockDate(dt)
      setLockSeconds(dt.diff(moment(), 'seconds'))
      setReason('lock_' + moment().unix())
    }
  }, [isOpen])

  const { address: parsedAddress } = useENS(recipient)

  function handleReasonType(val: string) {
    setReason(val)
  }

  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    setTypedValue('')
    setRecipient('')
    setTransfwerWithLock(false)
    onDismiss()
  }, [onDismiss])

  // pair contract for this token to be staked
  // const dummyPair = new Pair(
  //   new TokenAmount(balanceInfo.totalBalance.token, '0'),
  //   new TokenAmount(stakingInfo.tokens[1], '0')
  //)
  // approval data for stake

  const flyContract = useFlyContract()
  async function onTransfer() {
    setAttempting(true)
    if (flyContract && parsedAmount && parsedAddress) {
      if (transfwerWithLock) {
        if (lockSeconds < MIN_SECONDS_TO_LOCK || !reason) {
          setAttempting(false)
          throw new Error('Attempting to transfer with lock using wrong values. Please contact support.')
        }

        const args = [
          recipient,
          formatBytes32String(reason),
          `0x${parsedAmount.raw.toString(16)}`,
          lockSeconds.toFixed(0)
        ]
        await flyContract.estimateGas['transferWithLock'](...args, {}).then(estimatedGasLimit => {
          return flyContract
            .transferWithLock(...args, { value: null, gasLimit: calculateGasMargin(estimatedGasLimit) })
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: `Transfer with lock ${parsedAmount?.toExact()} FLy`
              })
              setHash(response.hash)
              return response.hash
            })
            .catch((error: any) => {
              setAttempting(false)
              console.log(error)
            })
        })
      } else {
        const args = [recipient, parsedAmount.raw.toString()]
        await flyContract.estimateGas['transfer'](...args, {}).then(estimatedGasLimit => {
          return flyContract
            .transfer(...args, { value: null, gasLimit: calculateGasMargin(estimatedGasLimit) })
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: `Transfer ${parsedAmount?.toExact()} FLy`
              })
              setHash(response.hash)
              return response.hash
            })
            .catch((error: any) => {
              setAttempting(false)
              console.log(error)
            })
        })
      }
    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    setTypedValue(typedValue)
  }, [])

  // used for max input button
  const maxAmountInput = maxAmountSpend(balanceInfo?.balance)
  const atMaxAmount = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))
  const handleMax = useCallback(() => {
    maxAmountInput && onUserInput(maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={95}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Transfer</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <CurrencyInputPanel
            value={typedValue}
            onUserInput={onUserInput}
            onMax={handleMax}
            showMaxButton={!atMaxAmount}
            currency={balanceInfo?.balance?.token}
            label={''}
            disableCurrencySelect={true}
            customBalanceText={'Available to transfer: '}
            id="stake-liquidity-token"
          />
          <AddressInputPanel id="recipient" value={recipient} onChange={handleRecipientType} />
          <RowBetween>
            <RowFixed>
              <TYPE.black fontWeight={400} fontSize={14} color={theme.text2}>
                Toggle transfer with Lock Mode
              </TYPE.black>
              <QuestionHelper text="Perform transfer with locking amount of tokens to specified date" />
            </RowFixed>
            <Toggle
              id="toggle-transfer-with-lock"
              isActive={transfwerWithLock}
              toggle={
                transfwerWithLock
                  ? () => {
                      setTransfwerWithLock(false)
                    }
                  : () => {
                      setTransfwerWithLock(true)
                    }
              }
            />
          </RowBetween>
          <AutoColumn gap="12px" justify={'start'}>
            {transfwerWithLock && (
              <>
                <ReasonInputPanel id="reason" value={reason} onChange={handleReasonType} />
                <DateTimeInputPanel
                  id="datelock"
                  value={lockDate}
                  onChange={(val, seconds) => {
                    setLockDate(val)
                    setLockSeconds(seconds)
                  }}
                />
              </>
            )}
          </AutoColumn>
          <RowBetween>
            <ButtonError
              disabled={
                !!error || !parsedAddress || (transfwerWithLock && (!reason || lockSeconds < MIN_SECONDS_TO_LOCK))
              }
              error={
                (!!error && !!parsedAmount) ||
                !parsedAddress ||
                (transfwerWithLock && (!reason || lockSeconds < MIN_SECONDS_TO_LOCK))
              }
              onClick={onTransfer}
            >
              {error ?? 'Transfer'}
            </ButtonError>
          </RowBetween>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transfering tokens</TYPE.largeHeader>
            <TYPE.body fontSize={20}>
              {parsedAmount?.toSignificant(4)} {balanceInfo?.balance?.token?.symbol}
            </TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>
              Trasferred {parsedAmount?.toSignificant(4)} {balanceInfo?.balance?.token?.symbol}2
            </TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
