import { parseUnits } from '@ethersproject/units'
import { Currency, CurrencyAmount, JSBI, Token, TokenAmount } from '@uniswap/sdk'
import { useMemo } from 'react'
import { FLY } from '../../constants'
import { FLY_TOKEN_INTERFACE } from '../../constants/abis/FLy-contract'
import { useActiveWeb3React } from '../../hooks'
import { useMultipleContractSingleData } from '../multicall/hooks'

export interface BalanceInfo {
  totalBalance: TokenAmount | undefined
  balance: TokenAmount | undefined
  lockedBalance: TokenAmount | undefined
  unlockableBalance: TokenAmount | undefined
}

// gets the staking info from the network for the active chain id
export function useBalanceInfo(): BalanceInfo {
  const { chainId, account } = useActiveWeb3React()
  const fly = chainId ? FLY[chainId] : undefined
  const accountArg = useMemo(() => [account ?? undefined], [account])

  // get all the info from the staking rewards contracts
  const balanceOf = useMultipleContractSingleData([fly?.address], FLY_TOKEN_INTERFACE, 'balanceOf', accountArg)
  const totalBalance = useMultipleContractSingleData([fly?.address], FLY_TOKEN_INTERFACE, 'totalBalanceOf', accountArg)
  const unlockableTokens = useMultipleContractSingleData(
    [fly?.address],
    FLY_TOKEN_INTERFACE,
    'getUnlockableTokens',
    accountArg
  )

  return useMemo(() => {
    if (!fly)
      return { totalBalance: undefined, unlockableBalance: undefined, lockedBalance: undefined, balance: undefined }
    const totalBalanceState = totalBalance[0]
    const balanceState = balanceOf[0]
    const unlockableState = unlockableTokens[0]

    if (
      totalBalanceState &&
      balanceState &&
      unlockableState &&
      !totalBalanceState.loading &&
      !balanceState.loading &&
      !unlockableState.loading
    ) {
      if (totalBalanceState?.error || balanceState?.error || unlockableState?.error) {
        console.error('Failed to load token balances')
        return { totalBalance: undefined, unlockableBalance: undefined, lockedBalance: undefined, balance: undefined }
      }
      const balance = new TokenAmount(fly, JSBI.BigInt(balanceState.result?.[0]))
      const total = new TokenAmount(fly, JSBI.BigInt(totalBalanceState.result?.[0]))
      const unlockBalance = new TokenAmount(fly, JSBI.BigInt(unlockableState.result?.[0]))
      return {
        totalBalance: total,
        balance: balance,
        unlockableBalance: unlockBalance,
        lockedBalance: total.subtract(balance).subtract(unlockBalance)
      }
    }
    return { totalBalance: undefined, unlockableBalance: undefined, lockedBalance: undefined, balance: undefined }
  }, [balanceOf, totalBalance, unlockableTokens, fly])
}

export function useTotalFly(): TokenAmount | undefined {
  const balanceInfo = useBalanceInfo()

  return useMemo(() => {
    if (!balanceInfo || !balanceInfo?.totalBalance) return undefined
    return balanceInfo.totalBalance
  }, [balanceInfo])
}

// try to parse a user entered amount for a given token
export function tryParseAmount(value?: string, currency?: Currency): CurrencyAmount | undefined {
  if (!value || !currency) {
    return undefined
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString()
    if (typedValueParsed !== '0') {
      return currency instanceof Token
        ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
        : CurrencyAmount.ether(JSBI.BigInt(typedValueParsed))
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error)
  }
  // necessary for all paths to return a value
  return undefined
}

// based on typed value
export function useTransferInfo(
  typedValue: string,
  balanceInfo: BalanceInfo
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, balanceInfo?.balance?.token)

  const parsedAmount =
    parsedInput && balanceInfo?.balance && JSBI.lessThanOrEqual(parsedInput.raw, balanceInfo.balance.raw)
      ? parsedInput
      : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}
